import React from "react";
import "./footerStyle.css";
import { FaFacebookSquare } from "react-icons/fa";
import {
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";

const Footer = () => {
  const change = (option) => {
    localStorage.setItem("lang", option.target.value);
    window.location.reload();
  };
  const lang = localStorage.getItem("lang") || "en";
  return (
    <div className="footerMaincontent bg-dark">
      <div className="footer-infos">
        <div className="footer-menu">
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#skill">Skill</a>
          <a href="#contuct">Contuct</a>
        </div>

        <div className="footer-socail">
          <p>You can find me on socail media !</p>
          <a href="https://www.facebook.com/asmaulsardr/">
            <FaFacebookSquare className="footer-socail-icon" />
            Facebook
          </a>
          <a href="https://www.instagram.com/asmaul_sarder/">
            <AiFillInstagram className="footer-socail-icon" />
            Instagram
          </a>
          <a href="https://www.linkedin.com/in/asmaul-sarder-04b6451b4/">
            <AiFillLinkedin className="footer-socail-icon" />
            Linkedin
          </a>
          <a href="https://twitter.com/SarderAsmaul">
            <AiFillTwitterSquare className="footer-socail-icon" />
            Twitter
          </a>
        </div>
      </div>
      <p className="footer-copyrigh">@ Asmaul Sarder 2021 Copyright Text</p>
      {/* <div className="language">
                <select className="custom-select pull-left" onChange={change} value={lang}>
                    <option>Select Language</option>
                    <option value="en">English</option>
                    <option value="ban">বাংলা</option>
                    <option value="ar">عربى</option>
                </select>

            </div> */}
    </div>
  );
};

export default Footer;
