import React, { useEffect } from "react";
import asmaul from "../About/AboutImages/asmaul.jpg";
import {} from "react-icons";
import Axios from "axios";
const Home = () => {
  // useEffect(()=>{
  //    async function getData(){
  //         try{
  //             let {data}=await Axios.get('http://127.0.0.1:8000/api/allstudents')
  //             console.log(data)
  //         }catch(error){
  //             console.log(error)
  //         }
  //     }
  //     getData()
  // },[])
  return (
    <div
      id="home"
      className="container mt-5 pt-3 px-5"
      style={{ background: "#1b1b1b0f" }}
    >
      <div className="row justify-content-center pb-4 pt-5">
        <div className="col-11 text-center text-x-sm-center text-md-start text-lg-start col-sm-11 col-md-5 col-lg-5">
          <h2 style={{ color: "#0d6efd" }}>Hi, I'm Asmaul</h2>
          <h6>Full Stack developer</h6>
          <p style={{ fontSize: "20px" }}>
            High level experience in web design and development
          </p>
          <div className="justify-content-center mt-5">
            <a
              style={{ fontSize: "18px" }}
              href="#contuct"
              className="btn btn-success"
            >
              Contact <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-1 col-lg-1">
          <div className="mt-4 text-center ">
            <ul
              className="home-icon d-sm-flex d-flex d-md-table justify-content-center pt-sm-3 pb-3"
              style={{ fontSize: "25px" }}
            >
              <li
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Instagram"
              >
                <a className="mt-2" href="www.facebook.com">
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a className="mt-2" href="">
                  <i className="fa fa-github"></i>
                </a>
              </li>
              <li>
                <a className="mt-2" href="">
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a className="mt-2" href="">
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 justify-content-center">
          <div className="text-center">
            <img
              width="70%"
              style={{
                filter: " hue-rotate(18deg) brightness(1.2) contrast(1.1)",
                borderBottomLeftRadius: " 138px",
                transform: "scale(1.1)",
              }}
              src={asmaul}
              alt="asmaul"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
