import React from 'react';
import { Link } from 'react-router-dom';
import asmaul from '../About/AboutImages/asmaul.jpg'
const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg fixed-top bg-light p-0">
  <div className="container-fluid">
    <a className="navbar-brand" style={{fontWeight:'bold',color:'#0d6efd'}} href="#"><img width="50px" style={{filter:' hue-rotate(18deg) brightness(1.2) contrast(1.1)',borderRadius:'50%',marginRight:'15px',border:'2px solid #0d6efd'}} src={asmaul} alt="asmaul" />
 ASMAUL SARDER</a>
    <button className="navbar-toggler p-0 m-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
      
      <i class="fa fa-bars navbar-toggler-icon" aria-hidden="true"></i>
    </button>
    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDarkDropdown">
      <ul className="navbar-nav text-center">
        <li style={{fontWeight:'bold'}} className="nav-item"><a className="nav-link" href="#home">Home</a></li>
        <li style={{fontWeight:'bold'}} className="nav-item"><a className="nav-link" href="#about">About</a></li>
        <li style={{fontWeight:'bold'}} className="nav-item"><a className="nav-link" href="#skill">Skills</a></li>
        <li style={{fontWeight:'bold'}} className="nav-item"><a className="nav-link" href="#education">Education</a></li>
        <li style={{fontWeight:'bold'}} className="nav-item"><a className="nav-link" href="#portfolio">Portfolio</a></li>
        <li style={{fontWeight:'bold'}} className="nav-item"><a className="nav-link" href="#services">Services</a></li>
        <li style={{fontWeight:'bold'}} className="nav-item"><a className="nav-link" href="#contuct">Contact</a></li>
      </ul>
    </div>
  </div>
</nav>


    );
}

export default Navbar;
